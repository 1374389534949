<style lang="less" scoped>
.cwf {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -44%);
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: rgba(249, 98, 47, 1);
        letter-spacing: 3px;
      }
      p:nth-child(2) {
        font-size: 55px;
        font-weight: 600;
        margin: 0;
      }
      p:nth-child(3) {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 5px;
        margin: 0 0 67px 0;
      }
      p:nth-child(4) {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 260px;
      }
    }
  }
  &-second {
    width: 100%;
    height: 550px;
    background: rgba(7, 9, 17, 1);
    position: relative;
    &-container {
      width: 76.7%;
      height: 100%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-top: 90px;
      color: #dee6ff;
      &-title {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      &-sub-title {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      &-main {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        &-item {
          width: 29.2%;
          img {
            width: 15.5%;
            display: block;
            margin: 0 auto;
          }
          p:nth-child(2) {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
          }
          p:nth-child(3) {
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  &-third {
    width: 100%;
    height: 800px;
    background: #0f1118;
    position: relative;
    color: rgba(222, 230, 255, 1);
    text-align: center;
    padding-top: 90px;
    &-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-subtitle {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-container-top {
      width: 34%;
      height: 50px;
      max-width: 590px;
      margin: 50px auto;
      overflow: hidden;
      img {
        width: 50px;
        cursor: pointer;
      }
    }
    &-container {
      width: 72.8%;
      height: 556px;
      max-width: 1049px;
      margin: 50px auto;
      overflow: hidden;
      position: relative;
      .swiper-slide {
        width: 90%;
        &-main {
          width: 86%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          &-left {
            width: 47.5%;
            text-align: left;
            padding-top: 161px;
            p:nth-child(1) {
              font-size: 38px;
              font-weight: 400;
              line-height: 53px;
              margin: 0;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              margin: 0;
            }
            p:nth-child(3) {
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
              margin-top: 24px;
            }
          }
          &-right {
            width: 52.3%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .r-button-moreGuests {
      position: absolute;
      top: 22.5%;
      left: 70%;
    }
  }
  /deep/
    .cwf-third-container.swiper-container-initialized.swiper-container-horizontal {
    .swiper-button-next,
    .swiper-button-prev {
      width: 51px;
      height: 51px;
      background: rgba(222, 230, 255, 1);
      border-radius: 50%;
      color: #0f1118;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      margin-left: 50px;
      margin-right: 50px;
      &:after {
        font-size: 14px;
        font-weight: bolder;
      }
    }
    .swiper-button-next {
      right: -50px;
    }
    .swiper-button-prev {
      left: -50px;
    }
  }
  /deep/ .cwf-third-container-top .swiper-slide {
    height: 100%;
    opacity: 0.4;
  }
  .cwf-third-container-top .swiper-slide-thumb-active {
    opacity: 1;
  }
  &-forth {
    width: 100%;
    height: 550px;
    background: rgba(7, 9, 17, 1);
    position: relative;
    &-container {
      width: 76.7%;
      height: 100%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-top: 90px;
      color: #dee6ff;
      &-title {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      &-sub-title {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      &-main {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        &-item {
          width: 14.5%;
          img {
            width: 35%;
            display: block;
            margin: 0 auto;
          }
          p:nth-child(2) {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
          }
          p:nth-child(3) {
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  &-fifth {
    width: 100%;
    height: 710px;
    background: rgba(15, 17, 24, 1);
    position: relative;
    &-container {
      width: 73.6%;
      height: 100%;
      max-width: 1060px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-top: 190px;
      color: rgba(222, 230, 255, 1);
      &-title {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      &-sub-title {
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      &-main {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        &-item {
          width: 20%;
          img {
            display: block;
            width: 120%;
            margin-left: -20%;
          }
          .first {
            width: 79%;
            margin-left: 11%;
          }
          .last {
            width: 79%;
            margin-left: -11%;
          }
          p {
            padding-right: 18px;
          }
        }
      }
    }
  }
  .about-us-third {
    width: 100%;
    height: 885px;
    background: #070911;
    position: relative;
    margin-top: 100px;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      .swiper-slide {
        width: 100%;
        img {
          display: block;
          width: 100%;
        }
        &-explain {
          width: 100%;
          height: 285px;
          background: rgba(15, 17, 24, 1);
          color: rgba(222, 230, 255, 1);
          position: relative;
          &-main {
            width: 77.8%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p:nth-child(1) {
              font-size: 20px;
              font-weight: 400;
              margin: 0;
            }
            p:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
            p:nth-child(3) {
              width: 90px;
              height: 30px;
              font-size: 12px;
              font-weight: 400;
              text-align: center;
              line-height: 30px;
              letter-spacing: 3px;
              border-radius: 4px;
              border: 1px solid rgba(222, 230, 255, 1);
              margin-top: 50px;
            }
          }
        }
      }
    }
  }
  .r-button-1 {
    margin: 60px auto;
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
  .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets {
    height: 24px;
    line-height: 24px;
    top: 62%;
  }
  /deep/
    .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 18px;
  }
  /deep/
    .swiper-pagination.swiper-pagination-render.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="cwf">
    <div class="cwf-first">
      <!-- <img src="../assets/home_cwf1.png"
           alt=""> -->
      <img src="../assets/swces.jpg"
           alt="">
      <div class="cwf-first-container">
        <!-- <p>
          11月25日-11月29日 中国·上海 丽水
        </p> -->
        <p>
          <!-- CREATIVITY WORLD FORUM -->
          Shanghai World Creative Economy Summit
        </p>
        <p>
          <!-- 世界创意峰会 -->
        </p>
        <div class="r-button"
             @click="linkTo('https://districtsofcreativity.org/projects/creativity-world-forum/', '_blank')">
          <!-- 报名参会 -->
          {{ $t('readMore') }}
        </div>
        <p>
          参会嘉宾： Carlo Vuijlsteke/ Ramón Vila/ Daan Roosegarde/ Milo Chao/ Éloi Beauchamp/ Sebastien Grenier-Cartier/ Claire Tousignant
        </p>
      </div>
    </div>
    <!-- <div class="cwf-second">
      <div class="cwf-second-container">
        <div class="cwf-second-container-title">
          关于世界创意峰会
        </div>
        <div class="cwf-second-container-sub-title">
          ABOUT CREATIVITY WORLD FORUM
        </div>
        <div class="cwf-second-container-main">
          <div class="cwf-second-container-main-item">
            <img src="../assets/Time.png"
                 alt="">
            <p>我们是什么</p>
            <p>
              世界创意经济峰会由欧洲领先的世界创意经济区域合作国际组织（Districts of Creativity Network，DC Network）发起，旨在汇聚全球的创意产业商业领袖、创意先锋、创业精英、来自高等院校的专家和学者以及政府部门代表，交流全球化背景下的前沿思想、创新方案、创意商机，带来令人激动的创意、创新思维和想象力。
            </p>
          </div>
          <div class="cwf-second-container-main-item">
            <img src="../assets/Server.png"
                 alt="">
            <p>我们做什么</p>
            <p>
              CWF作为DC Network的旗舰会议，每年由DC Network成员国轮流举办。每届大会都吸引超过1500与会者参与创新、创意和创业的探讨和交流。通过论坛、展览、企业考察、工作坊以及创意大赛等多种创新形式，来自全球的与会者将在大会上分享前沿观点、激发创新与创意、展示创新方案、建立国际合作。
            </p>
          </div>
          <div class="cwf-second-container-main-item">
            <img src="../assets/Settings.png"
                 alt="">
            <p>为什么参会</p>
            <p>
              2020年世界创意大会将由DC Network成员中国上海DC（长三角创意经济合作专业委员会，CECC）负责主办。大会将为与会者建立与国际创意组织DC Network的紧密联系，为与会者打开通过国际的大门。每年会议主题将聚焦当今经济和社会热点，注重将全球问题和当地问题将结合，致力推动各地区创意产业的发展以及与全球各区域的联结与融合。
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="cwf-third">
      <div class="cwf-third-title">
        参会嘉宾阵容
      </div>
      <div class="cwf-third-subtitle">
        HONORED GUESTS
      </div>
      <div class="cwf-third-container-top">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/cwf_s_1.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_2.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_3.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_4.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_5.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_6.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/cwf_s_7.png"
                 alt="">
          </div>
        </div>
      </div>
      <div class="cwf-third-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Carlo Vuijlsteke
                </p>
                <p>
                  DC Network 联合创始人
                </p>
                <p>
                  DC Network是欧洲比利时佛兰德地区创意产业的主要支持组织，也是创意国际区网络的发起者。DC Network是一个由世界各地的区域组成的网络，致力于激发创新和繁荣。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_1.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Ramón Vila
                </p>
                <p>
                  上海迪士尼乐园 艺术总监
                </p>
                <p>
                  Ramón Vila在加拿大、墨西哥和美国学习建筑，在西班牙马德里学习戏剧、表演艺术和艺术指导。他擅长唱歌、表演、设计，并在西班牙和墨西哥的音乐剧中演出。现在，他为所有迪士尼中季节性的活动设计装饰，如万圣节、春节、圣诞节、夏天、开幕式、红地毯和游行花车等。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_2.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Daan Roosegarde
                </p>
                <p>
                  上海同济大学环境设计系 客座教授
                </p>
                <p>
                  Daan Roosegarde来自荷兰，他于2007年创建了Roosegarde工作室，在那里他与团队一起致力于更好的未来，共同开发“未来景观”，为未来城市构建智能可持续的原型。Roosegarde被任命为世界经济论坛（World Economic Forum）全球青年领袖、荷兰2016年度艺术家。被《福布斯》评选为“WIRED and Good 100-7创意变革者”
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_3.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Milo Chao
                </p>
                <p>
                  Cao＆Chao Show 创始人及主持人
                </p>
                <p>
                  Milo Chao，出生在都柏林，成长在皇后区。他专注关于中国的创意，采访那些在创意领域获得一定国际认可的中国人。该项目引起了电视名人曹景兴的注意，一起开始播客。Milo认为真正的故事不在于中国能教给世界什么是创造力，而在于中国需要什么样的创造力。他正在录制播客的第二季，将来还会与不少企业家、创始人和广告创意人士进行交流。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_4.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Éloi Beauchamp
                </p>
                <p>
                  Éloi Beauchamp
                </p>
                <p>
                  1999年，当时是生物专业硕士学生的Éloi Beauchamp 在蒙特利尔创立了L’Éloi公司。这个概念很简单：代表杰出的摄影师，并提供制作服务来促进他们的过程，让他们能够充分表达自己，专注于他们的艺术。<br />
                  10年后，随着电影和艺术导演加入其团队，业务得到了发展。该机构以其艺术家的素质脱颖而出，他们不断地突破限制。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_5.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Sebastien Grenier-Cartier
                </p>
                <p>
                  Normal Studio CEO
                </p>
                <p>
                  Sébastien曾担任过CitéMémoire和Cirque du Soleil以及Sep7imo Dia秀的技术总监，在掌管Normal之前，Sébastien是该工作室的技术设计师已有五年多了。 他天生具有技术天赋，设计并主导过大型项目的系统，如Robert Lepage的LeMoulinàimage（Ex Machina制作公司），以及2010年温哥华奥运会的仪式。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_6.png"
                     alt="">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="swiper-slide-main">
              <div class="swiper-slide-main-left">
                <p>
                  Claire Tousignant
                </p>
                <p>
                  MASSIVart 执行合伙人
                </p>
                <p>
                  Claire以优异成绩从渥太华大学和悉尼大学获得硕士学位后，与全球最大的金融公司如宏利AM、法国巴黎银行、怡安和枫叶金融公司的高管合作，以支持他们在加拿大的发展。她开发了魁北克政府在上海的第一个创意产业展示项目MASSIVart，涉及60多个当地和国际伙伴，并于2018年1月，MASSIVart迎接了总理代表团。
                </p>
              </div>
              <div class="swiper-slide-main-right">
                <img src="../assets/cwf_s1_7.png"
                     alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="r-button r-button-moreGuests"
           @click="linkTo('https://mp.weixin.qq.com/mp/homepage?__biz=MzIyMzM4MzgwMg==&hid=22&sn=4ec372a8e34aa7d4a49af0455f2b4cc8', '_blank')">
        {{ $t('moreGuests') }}
      </div>
    </div>
    <!-- <div class="cwf-forth">
      <div class="cwf-forth-container">
        <div class="cwf-forth-container-title">
          大会板块
        </div>
        <div class="cwf-forth-container-sub-title">
          MODULES
        </div>
        <div class="cwf-forth-container-main">
          <div class="cwf-forth-container-main-item">
            <img src="../assets/5.png"
                 alt="">
            <p>
              主题演讲
            </p>
            <p>
              智慧云集共享，邀请创意经济领域的领军人物、专家学者、商业领袖、先锋创业者等。
            </p>
          </div>
          <div class="cwf-forth-container-main-item">
            <img src="../assets/3.png"
                 alt="">
            <p>
              创意工作坊
            </p>
            <p>
              组织与会专家考察当地创意产业及相关先锋企业，分地区、分主题与企业开展深入互动与交流，探索合作商机。
            </p>
          </div>
          <div class="cwf-forth-container-main-item">
            <img src="../assets/2.png"
                 alt=""
                 style="width:22%">
            <p>
              创新社交活动
            </p>
            <p>
              为与会的商业领袖、创意人才、政府机构、社会组织提供充分的机会建立至关重要的创意商业网络。
            </p>
          </div>
          <div class="cwf-forth-container-main-item">
            <img src="../assets/6.png"
                 alt="">
            <p>
              创意资源对接会
            </p>
            <p>
              联合国际专业创意机构及高等院校，对接国际创意人的创意作品与创新方案，结合经济与社会问题寻找商业方案。
            </p>
          </div>
          <div class="cwf-forth-container-main-item">
            <img src="../assets/5.png"
                 alt="">
            <p>
              创意设计大赛
            </p>
            <p>
              选拔全球创意先锋，探索独特创意与当地企业或资源的融合，对接具有商业价值的创意作品与创新解决方案。
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="cwf-fifth">
      <div class="cwf-fifth-container">
        <div class="cwf-fifth-container-title">
          CWF大会日程
        </div>
        <div class="cwf-fifth-container-sub-title">
          SCHEDULE
        </div>
        <div class="cwf-fifth-container-main">
          <div class="cwf-fifth-container-main-item">
            <img src="../assets/calendar/icon-1.png"
                 alt=""
                 class="first">
            <p>
              2020.11.25 <br />
              数字魔都 · 科技南翔
            </p>
            <p>
              CWF上海主会场开幕式<br />
              <br />
              数字设计与创意空间<br />
              领略智慧南翔 感受传承创新<br />
              <br />
              上海浦江创意之夜<br />
              ”秋日浦江” 欢迎晚宴<br />
            </p>
          </div>
          <div class="cwf-fifth-container-main-item">
            <img src="../assets/calendar/icon-2.png"
                 alt="">
            <p>
              2020.11.26<br />
              创意工坊 · 智慧激荡
            </p>
            <p>
              丽水创意工作坊<br />
              <br />
              古堰画乡：传统艺术体验<br />
              古坊古街：非遗传承手艺<br />
              古法酿造：生态农业与食品安全<br />
              <br />
              “丽水瓯江之夜” 欢迎晚宴<br />
            </p>
          </div>
          <div class="cwf-fifth-container-main-item">
            <img src="../assets/calendar/icon-2.png"
                 alt="">
            <p>
              2020.11.27<br />
              创意融合 · 跨界智造
            </p>
            <p>
              全球创意资源对接会<br />
              <br />
              文旅产业与IP开发<br />
              生态农业与食品安全<br />
              旅游文化小镇开发模式<br />
              <br />
              “创意之夜” 社交晚宴<br />
            </p>
          </div>
          <div class="cwf-fifth-container-main-item">
            <img src="../assets/calendar/icon-2.png"
                 alt="">
            <p>
              2020.11.28<br />
              创意设计 · 无边力量
            </p>
            <p>
              全球创意设计大赛颁奖盛典
              DC Living Lab Road Show
              (CWF专属活动之一)
            </p>
          </div>
          <div class="cwf-fifth-container-main-item">
            <img src="../assets/calendar/icon-3.png"
                 alt=""
                 class="last">
            <p>
              2020.11.29<br />
              开放创新 · 共创未来
            </p>
            <p>
              第三届中国国际进口博览会参观<br />
              时尚产业数字化与AI应用<br />
              设计思维与数字时尚生活<br />
              <br />
              CWF闭幕晚宴<br />
            </p>
          </div>
        </div> -->
    <!-- <div class="r-button r-button-1">
          报名参会
        </div> -->
    <!-- </div>
    </div> -->
    <div class="about-us-third">
      <div class="about-us-third-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/about-us-1.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?413.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q59')}}
                </p>
                <p>
                  {{$t('Q60')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <!-- <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{ $t('morePhotos') }}
                </p> -->
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-2.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?413.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q61')}}
                </p>
                <p>
                  {{$t('Q62')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <!-- <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{ $t('morePhotos') }}
                </p> -->
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-3.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?396.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q63')}}
                </p>
                <p>
                  {{$t('Q64')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <!-- <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{ $t('morePhotos') }}
                </p> -->
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="../assets/about-us-4.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?368.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  {{$t('Q65')}}
                </p>
                <p>
                  {{$t('Q66')}}
                </p>
                <!-- <p @click="linkTo('http://www.cecc.sh.cn/product/class/?74.html', '_blank')"
                   :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{$t('Q58')}}
                </p> -->
                <!-- <p :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                  {{ $t('morePhotos') }}
                </p> -->
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <img src="../assets/about-us-1.png"
                 alt=""
                 @click="linkTo('http://www.cecc.sh.cn/news/html/?368.html', '_blank')">
            <div class="swiper-slide-explain">
              <div class="swiper-slide-explain-main">
                <p>
                  2015上海世界创意经济峰会召开
                </p>
                <p>
                  2015年首届上海世界创意经济峰会国际论坛在东华大学召开，爱丁堡作为主宾城市，峰会主题为“互联网视域下创意产业发展的范式创新和政策选择”，当年活动规模500人，被评为上海市创意产业协会的优秀活动项目。
                  <p @click="linkTo('http://www.cecc.sh.cn/news/html/?432.html', '_blank')" :style="{width: $Cookies.get('cecc-lang') === 'en' ? '120px' : ''}">
                     {{$t('Q58')}}
                  </p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-pagination swiper-pagination-render"></div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonFooter from '../components/common-footer';
import CommonActivies from '../components/common-activities';

export default {
  name: 'CWF',
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') !== 'en') this.$router.push({
      name: 'CWF'
    })
  },
  mounted () {
    new Swiper('.about-us-third-container', {
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    })
    let cwfThirdContaine = new Swiper('.cwf-third-container-top', {
      slidesPerView: 7,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
    new Swiper('.cwf-third-container', {
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: cwfThirdContaine,
      },
    })
  },
  computed: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  }
}
</script>
